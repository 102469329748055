import React from 'react'
import Rooms from "../../components/Rooms"
const RoomsPage = () => {
  return (
    <>
    <div className="rooms">
        <Rooms/>
    </div>
    </>
  )
}

export default RoomsPage