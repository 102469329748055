import React from "react";
import Amenities from "../../components/Amenities";
import Branches from "../../components/Branches";
import Intro from "../../components/Intro";
import Rooms from "../../components/Rooms";
import Slider from "../../components/Slider";
import Packages from "../../components/Packages";
import Testimonials from "../../components/Testimonials";
import ContactBox from "../../components/ContactBox";

const Home = () => {
  return (
    <>
      <Slider />
      <Intro />
      <Branches />
      <Rooms />
      <Amenities />
      {/* <Packages /> */}
      <Testimonials />
      {/* <ContactBox /> */}
    </>
  );
};

export default Home;
