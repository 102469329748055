import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TitleBlock from '../TitleBlock';
import RoomImage from '../../assets/images/brand/super-delux-rooms.jpg';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
const Rooms = () => {
  const GET_ROOMS = gql`
    query NewQuery {
      rooms {
        nodes {
          title
          slug
          rooms {
            name
            description
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  return (
    <>
      <Query query={GET_ROOMS}>
        {({ data: { rooms } }) => {
          return (
            <section className='roomsWrapper'>
              <TitleBlock
                number='03'
                pretitle='explore'
                title='Room & Suits'
                desc='A luxurious collection of rooms and suites featuring spacious interiors and conveniences that will make your stay memorable.'
              />

              <section>
                <Container>
                  <Row className='gy-5'>
                    <>
                      {rooms.nodes.map((a) => {
                        return (
                          <Col md={6}>
                            <section className='roomCard'>
                              <figure className='roomCard-image'>
                                <img src={a?.rooms?.image?.sourceUrl} alt='' />
                              </figure>
                              <h2 className='roomCard-title'>{a?.title}</h2>

                              <section className='roomCard-attributes'>
                                {/* <span>
                            30m<sup>2</sup> .
                          </span>
                          <span>2 Beds .</span>
                          <span>2 Guests </span> */}
                                {a.rooms.description}
                              </section>

                              <section className='text-center pb-3'>
                                <Link
                                  to={`/roomdescription/${a.slug}`}
                                  className='bton bton--md bton--ghost'
                                >
                                  Further Information
                                </Link>
                              </section>
                            </section>
                          </Col>
                        );
                      })}
                    </>
                  </Row>
                </Container>
              </section>
            </section>
          );
        }}
      </Query>
    </>
  );
};

export default Rooms;
