import React from 'react';
import emailjs from '@emailjs/browser';
import { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReservationForm = () => {
  const GET_BRANCH = gql`
    query NewQuery {
      branches {
        nodes {
          title
        }
      }
    }
  `;
  const GET_ROOMS = gql`
    query NewQuery {
      rooms {
        nodes {
          title
          slug
          rooms {
            name
            description
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  const { state } = useLocation();
  const { checkIn, checkOut, selectBranch, roomName, selectRoom } = state;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [formCheckIn, setFormCheckIn] = useState(checkIn);
  const [formCheckOut, setFormCheckOut] = useState(checkOut);
  const [formBranch, setFormBranch] = useState(selectBranch);
  const [formRoom, setFormRoom] = useState(roomName);
  const [roomHome, setRoomHome] = useState(selectRoom);
  const [personNum, setPersonNum] = useState('');
  const [roomNum, setRoomNum] = useState('');
  const [message, setMessage] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setPhoneError(true);
      return false;
    }
    if (!phone) {
      return false;
    } else {
      emailjs
        .sendForm(
          'service_gqfytqj',
          'template_7qap2h3',
          form.current,
          '2xWMX5XwpZ8kalz_E'
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              toast.success('Form Submitted Successfully');
            }
          },
          (error) => {
            if (error.text) {
              toast.error('Something went wrong');
            }
          }
        );
      setName('');
      setAddress('');
      setEmail('');
      setFormBranch('');
      setFormCheckIn('');
      setFormCheckOut('');
      setFormRoom('');
      setMessage('');
      setPersonNum('');
      setPhone('');
      setRoomNum('');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='reservationwrapper'>
      <ToastContainer />
      <h1>Book Now</h1>
      <Form ref={form} onSubmit={sendEmail}>
        <Row>
          <Col md={6}>
            <Form.Label>Full Name</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='text'
                placeholder='Full Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                name='name'
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>Email Address</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='email'
                placeholder='Email Address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name='email'
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>Address</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='text'
                placeholder='Address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name='address'
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>Phone Number</Form.Label>
            <Form.Group controlId='formBasicEmail'>
              <Form.Control
                type='number'
                placeholder='Phone Number'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name='phone'
                required
              />
            </Form.Group>
            {phoneError && phone.length !== 10 && (
              <p
                style={{
                  fontSize: '12px',
                  color: 'red',
                }}
              >
                Phone Number must be 10 digits.
              </p>
            )}
          </Col>
          <Col md={6}>
            <Form.Label>CheckIn</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='date'
                placeholder='CheckIn'
                value={formCheckIn}
                onChange={(e) => setFormCheckIn(e.target.value)}
                name='formCheckIn'
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>CheckOut</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='date'
                placeholder='Check Out'
                value={formCheckOut}
                onChange={(e) => setFormCheckOut(e.target.value)}
                name='formCheckOut'
                required
              />
            </Form.Group>
          </Col>
          <Query query={GET_BRANCH}>
            {({ data: { branches } }) => {
              return (
                <>
                  <Col>
                    <Form.Label>Select Branch</Form.Label>

                    <Form.Select
                      aria-label='Default select example'
                      value={formBranch}
                      onChange={(e) => setFormBranch(e.target.value)}
                      name='formBranch'
                      required
                    >
                      {/* <option> Choose Branch</option> */}
                      {branches.nodes?.map((a) => {
                        return (
                          <>
                            <option value={a.title}>{a.title}</option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </>
              );
            }}
          </Query>
          {selectRoom ? (
            <>
              <Query query={GET_ROOMS}>
                {({ data: { rooms } }) => {
                  return (
                    <>
                      <Col md={6} className='mb-4'>
                        <Form.Label>Select Room</Form.Label>

                        <Form.Select
                          aria-label='Default select example'
                          value={roomHome}
                          onChange={(e) => setRoomHome(e.target.value)}
                          name='roomHome'
                          required
                        >
                          <option>Select Room</option>
                          {rooms.nodes?.map((a) => {
                            return (
                              <>
                                <option value={a.rooms.name}>
                                  {a.rooms.name}
                                </option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </>
                  );
                }}
              </Query>
            </>
          ) : (
            <>
              <Query query={GET_ROOMS}>
                {({ data: { rooms } }) => {
                  return (
                    <>
                      <>
                        <Col md={6} className='mb-4'>
                          <Form.Label>Select Room</Form.Label>

                          <Form.Select
                            aria-label='Default select example'
                            value={formRoom}
                            onChange={(e) => setFormRoom(e.target.value)}
                            name='formRoom'
                            required
                          >
                            <option>Choose Room</option>
                            {rooms.nodes?.map((a) => {
                              return (
                                <option value={a?.title}>{a?.title}</option>
                              );
                            })}
                          </Form.Select>
                        </Col>
                      </>
                    </>
                  );
                }}
              </Query>
            </>
          )}
          <Col md={6}>
            <Form.Label>Number of Persons</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='number'
                placeholder='Number of Person'
                value={personNum}
                onChange={(e) => setPersonNum(e.target.value)}
                name='personNum'
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Label>Number of Rooms</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                type='number'
                placeholder='Number of Rooms'
                value={roomNum}
                onChange={(e) => setRoomNum(e.target.value)}
                name='roomNum'
                required
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Label>Message</Form.Label>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Control
                as='textarea'
                placeholder='Type your message'
                rows={5}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                required
              />{' '}
            </Form.Group>
          </Col>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <button className='btonn bton--lg bton--ghost' type='submit'>
            Book Now
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ReservationForm;
