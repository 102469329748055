import React from 'react';
import { Container } from 'react-bootstrap';

const TitleBlock = ({ title, pretitle, number, desc }) => {
  return (
    <Container>
      <section className='titleBlockHolder'>
        {window.location.href.includes("offer")?
        <p className="offer">Offers</p>:
       window.location.href.includes("rooms")?
       <p className="offer">Explore</p>:


        <section className='titleBlockHolder-preTitle'>
          {number} <div className='line'></div> {pretitle}
        </section>
        }

        <h1 className='titleBlockHolder-title'>{title}</h1>
        <p className='titleBlockHolder-desc'>{desc}</p>
      </section>
    </Container>
  );
};

export default TitleBlock;
