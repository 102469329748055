import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import TitleBlock from '../TitleBlock';
const Testimonials = () => {
  const rot = ['fsda', 'sdf', 'as'];
  return (
    <div className='testominial-Wrapper'>
      <TitleBlock
        number='05'
        pretitle='Testimonials'
        title='What Our Guest Say'
        desc=" Consider this your new travel guide. Here's what our guests have to say about their experiences.
        "
      />

      <Container>
        <Carousel>
          <Carousel.Item>
            <section className='testiWrapper'>
              <span className='testiWrapper-icon'></span>
              <p className='testiWrapper-desc'>
                The food was absolutely fantastic. We got the corn salt n
                pepper, chicken pauwa tikka, marouti chicken and timure chicken
                which was delicious. The spices are so unique and they are
                blended perfectly. You get a great amount of food and the price
                is so reasonable, we had never had food from City of butwal
                before. Wish you keep continue good work. Recommend A MUST
                visit.
              </p>
              <div className='testiWrapper-name'>
                {' '}
                <strong>Bhim Gaha</strong>
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className='testiWrapper'>
              <span className='testiWrapper-icon'></span>
              <p className='testiWrapper-desc'>
                One of the best hotel in the town not so expensive can be
                affordable too Highly recommended for everyone to visit while
                being in the town . Clean rooms n restrooms Very polite n humble
                hospitality Eth is perfect.. Just loved the hospitality One n
                only suggestion is ; providing complementary drink n breakfast
                would be excellent
              </p>
              <div className='testiWrapper-name'>
                {' '}
                <strong>The Saksham</strong>
              </div>
            </section>
          </Carousel.Item>
          <Carousel.Item>
            <section className='testiWrapper'>
              <span className='testiWrapper-icon'></span>
              <p className='testiWrapper-desc'>
                A well managed hotel in butwal with separate parking, restaurant
                and conventional center. Since the the hotel is upto 6 floor's,
                a well managed transparent lift is provided for easy access.
                Price is quite expensive but location, service, facility and
                hospitality justify the price, here I will upload the food menu
                in photo section.
              </p>
              <div className='testiWrapper-name'>
                {' '}
                <strong>Ramkailash Yadav</strong>
              </div>
            </section>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
};

export default Testimonials;
