import React from 'react';
import { Container } from 'react-bootstrap';
import gql from 'graphql-tag';
import Query from '../../components/Query';
// import Gallery1 from "../../assets/images/brand/gallery1.png"
// import Gallery2 from "../../assets/images/brand/gallery2.png"
// import Gallery3 from "../../assets/images/brand/gallery3.png"
// import Gallery4 from "../../assets/images/brand/gallery4.png"
// import Gallery5 from "../../assets/images/brand/gallery5.png"
// import Gallery6 from "../../assets/images/brand/gallery6.png"

const Gallery = () => {
  // const galleryphoto=[
  //     Gallery1,Gallery2,Gallery3,Gallery4,Gallery5,Gallery6
  // ]

  const GET_GALLERY = gql`
    query NewQuery {
      assets {
        nodes {
          title
          slug
          assets {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  return (
    <>
      <Container>
        <div className='gallery'>
          <p className='gallery__us'>Photos</p>
          <h1>Our Gallery</h1>
          <p className='gallery__desc'>
            Parturient malesuada nibh adipiscing cras. Morbi integer eget amet,
            sagittis cras sollicitudin pretium elit eu. Vel quis et auctor
            sodales urna nulla risus eu. Blandit in suspendisse tortor mattis
            enim.
          </p>
          <div className='gallery__photos mt-5'>
            <Query query={GET_GALLERY}>
              {({ data: { assets } }) => {
                return (
                  <>
                    {assets?.nodes[0]?.assets.image.map((b) => {
                      return (
                        <figure className='gallery__photos--inner'>
                          <img src={b.sourceUrl} alt='' />
                        </figure>
                      );
                    })}
                  </>
                );
              }}
            </Query>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Gallery;
