import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TitleBlock from '../TitleBlock';
import { AiOutlineWifi } from 'react-icons/ai';
import { TbSwimming } from 'react-icons/tb';
import { MdOutlineFastfood } from 'react-icons/md';
import { IoIosPeople } from 'react-icons/io';
import { GiFlowerPot } from 'react-icons/gi';
import { BiBed } from 'react-icons/bi';
import { BiHotel } from 'react-icons/bi';
import { BsPersonCheck } from 'react-icons/bs';
import { MdOutlineAir } from 'react-icons/md';
import { GiVacuumCleaner } from 'react-icons/gi';
import { MdOutlineLocalAirport } from 'react-icons/md';
import { BiDrink } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbParking } from 'react-icons/tb';

const Amenities = () => {
  return (
    <div>
      {window.location.href.includes('branch') ? (
        ''
      ) : (
        <TitleBlock
          number='04'
          pretitle='FEATURES'
          title='amenities'
          desc=' Get Closer look what we have
      '
        />
      )}

      <section>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <AiOutlineWifi />
                <div className='animitiesBox-title'>Wi-Fi</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <TbSwimming />
                <div className='animitiesBox-title'>Swimming Pool</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <MdOutlineFastfood />
                <div className='animitiesBox-title'>Delicious Food</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <IoIosPeople />
                <div className='animitiesBox-title'>Seminar Hall</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <GiFlowerPot />
                <div className='animitiesBox-title'>Garden</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <BiBed />
                <div className='animitiesBox-title'>Luxurious Room</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <BsPersonCheck />
                <div className='animitiesBox-title'>
                  24 hour guest reception
                </div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <BiHotel />
                <div className='animitiesBox-title'>Room Service</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <MdOutlineAir />
                <div className='animitiesBox-title'>Air Conditioning</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <GiVacuumCleaner />
                <div className='animitiesBox-title'>Daily Maid Service</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <MdOutlineLocalAirport />
                <div className='animitiesBox-title'>Airport shuttle</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <BiDrink />
                <div className='animitiesBox-title'>Bar</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <TbParking />
                <div className='animitiesBox-title'>Free Parking</div>
              </section>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <section className='animitiesBox'>
                <HiOutlineUserGroup />
                <div className='animitiesBox-title'>Meeting Facilities</div>
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Amenities;
