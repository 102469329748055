import React from 'react'
import Packages from '../../components/Packages'

const Offer = () => {
  return (
    <>
    <div className='offer-page'>
        <Packages/>
    </div>
    </>
  )
}

export default Offer