import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import { Col, Container, Row, Modal, Form } from 'react-bootstrap';
import TitleBlock from '../TitleBlock';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
const Packages = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [eventType, setEventType] = useState('');
  const [numOfPerson, setNumOfPerson] = useState('');
  const [date, setDate] = useState('');
  const [branch, setBranch] = useState('');
  const [message, setMessage] = useState('');
  const [packageName, setPackageName] = useState('');
  const GET_BRANCH = gql`
    query NewQuery {
      branches {
        nodes {
          title
        }
      }
    }
  `;
  const GET_OFFER = gql`
    query NewQuery {
      offers {
        nodes {
          title
          offers {
            description
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const form = useRef();

  const enquiryForm = (name) => {
    setPackageName(name);
    handleShow();
  };
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_j5dz6eq',
        'template_kp53nwe',
        form.current,
        'bf1hpQKsdwYZtMALp'
      )
      .then(
        (result) => {
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );
  };
  return (
    <div style={{ background: '#F9F6EE' }} className='component_bg'>
      {window.location.href.includes('branch') ? (
        ''
      ) : (
        <TitleBlock
          number='05'
          pretitle='Offers'
          title='Packages & Specials'
          desc='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate
          aliquid nesciunt pariatur reprehenderit, possimus labore eligendi
          beatae facilis eum maiores. Laudantium repellat veniam obcaecati sed
          dolores laborum unde placeat quod.'
        />
      )}
      <Query query={GET_OFFER}>
        {({ data: { offers } }) => {
          return (
            <Container>
              <Row>
                {offers?.nodes?.map((m) => {
                  return (
                    <Col md={4}>
                      <section
                        className='branchCard '
                        style={{ background: 'white' }}
                      >
                        <figure className='branchCard-image'>
                          <img src={m?.offers?.image?.sourceUrl} alt='' />
                        </figure>
                        <h2 className='branchCard-title'>{m?.title}</h2>

                        <p
                          className='branchCard-paragraph'
                          dangerouslySetInnerHTML={{
                            __html: m.offers?.description,
                          }}
                        />

                        <section className='text-center'>
                          <Link
                            to=''
                            className='bton bton--md bton--ghost'
                            onClick={() => enquiryForm(m?.title)}
                          >
                            Make an Enquiry{' '}
                          </Link>
                        </section>
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Make an Inquiry</Modal.Title>
                          </Modal.Header>
                          <Form onSubmit={sendEmail} ref={form}>
                            <Modal.Body>
                              <Row>
                                <Col md={6}>
                                  <div className='personal-info'>
                                    <p>personal Information</p>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter your fullname'
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                        name='name'
                                      />
                                    </Form.Group>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter your email address'
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                        name='email'
                                      />
                                    </Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Enter your phone number'
                                        value={phone}
                                        onChange={(e) =>
                                          setPhone(e.target.value)
                                        }
                                        name='phone'
                                      />
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className='personal-info'>
                                    <p>Event Information</p>
                                    <Form.Label>Event Type</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Eg:meeting & Conference'
                                        value={eventType}
                                        onChange={(e) =>
                                          setEventType(e.target.value)
                                        }
                                        name='eventType'
                                      />
                                    </Form.Group>
                                    <Form.Label>Number of Persons</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Total guests'
                                        value={numOfPerson}
                                        onChange={(e) =>
                                          setNumOfPerson(e.target.value)
                                        }
                                        name='numOfPerson'
                                      />
                                    </Form.Group>
                                    <Form.Label>Date and Time</Form.Label>
                                    <Form.Group
                                      className='mb-4'
                                      controlId='formBasicEmail'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Eg:2020/02/02 4:00 PM'
                                        value={date}
                                        onChange={(e) =>
                                          setDate(e.target.value)
                                        }
                                        name='date'
                                      />
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col>
                                  <Form.Label>Packages Name</Form.Label>
                                  <Form.Group
                                    className='mb-4'
                                    controlId='formBasicEmail'
                                  >
                                    <Form.Control
                                      type='input'
                                      placeholder='Package Name'
                                      value={packageName}
                                      onChange={(e) =>
                                        setPackageName(e.target.value)
                                      }
                                      name='packageName'
                                    />{' '}
                                  </Form.Group>
                                </Col>
                                <Query query={GET_BRANCH}>
                                  {({ data: { branches } }) => {
                                    return (
                                      <>
                                        {branches.nodes?.map((a) => {
                                          return (
                                            <>
                                              <div className='mb-3'>
                                                <Form.Label>
                                                  Select Branch
                                                </Form.Label>

                                                <Form.Select
                                                  aria-label='Default select example'
                                                  value={branch}
                                                  onChange={(e) =>
                                                    setBranch(e.target.value)
                                                  }
                                                  name='branch'
                                                >
                                                  <option>
                                                    {' '}
                                                    Choose Branch
                                                  </option>

                                                  <option value={a.title}>
                                                    {a.title}
                                                  </option>
                                                </Form.Select>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  }}
                                </Query>
                                <Col md={12}>
                                  <Form.Label>Message</Form.Label>
                                  <Form.Group
                                    className='mb-4'
                                    controlId='formBasicEmail'
                                  >
                                    <Form.Control
                                      as='textarea'
                                      placeholder='Type your message'
                                      rows={5}
                                      value={message}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                      name='message'
                                    />{' '}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                onClick={handleClose}
                                className='bton bton--md bton--ghost'
                                type='submit'
                              >
                                Save Changes
                              </button>
                            </Modal.Footer>
                          </Form>
                        </Modal>
                      </section>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          );
        }}
      </Query>
    </div>
  );
};

export default Packages;
